import { async } from 'regenerator-runtime';
import ApiService from './api.service';

class AdminError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const AdminService = {
  fetchAdminStats: async function () {
    try {
      const response = await ApiService.get('/admin/stats');
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  fetchOutbox: async function (otherQueryParams) {
    try {
      if (otherQueryParams) {
        const response = await ApiService.get(
          `/admin/messages/outbox${otherQueryParams}`
        );

        return response.data;
      }
      const response = await ApiService.get('/admin/messages/outbox');
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  fetchSenderNames: async function (id) {
    try {
      if (id) {
        const response = await ApiService.get(`/admin/sender-names/${id}`);
        return response.data;
      }
      const response = await ApiService.get('/admin/sender-names');
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  fetchUsers: async function (otherQueryParams) {
    try {
      if (otherQueryParams) {
        const response = await ApiService.get(
          `/admin/users${otherQueryParams}`
        );
        return response.data;
      }
      const response = await ApiService.get(`/admin/users`);
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  fetchPaymentModes: async function () {
    try {
      const response = await ApiService.get('/back-office/payment-modes');
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  fetchCurrencies: async function () {
    try {
      const response = await ApiService.get('/back-office/currencies');
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  creditUnits: async function (data) {
    try {
      const response = await ApiService.post(
        '/admin/credit-user-purchase',
        data
      );
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  // INTERNAL STAFFS ENDPOINTS

  fetchInternalStaffsTypes: async function () {
    try {
      const response = await ApiService.get('/internal-staffs/types');
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  fetchInternalStaffsRoles: async function () {
    try {
      const response = await ApiService.get('/internal-staffs/roles');
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  postInternalStaffs: async function (data) {
    try {
      const response = await ApiService.post('/internal-staffs', data);
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },

  patchInternalStaffs: async function (data) {
    try {
      const response = await ApiService.patch('/internal-staffs', data);
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },
  patchSenderName: async function (data) {
    try {
      const response = await ApiService.patch('/admin/sender-names', data);
      return response.data;
    } catch (error) {
      throw new AdminError(error.response.status, error.response.data.detail);
    }
  },
};

export { AdminService, AdminError };
