import { UserService, AuthenticationError } from '@/services/user.service';
import { StorageService } from '@/services/storage.service';
import ApiService from '@/services/api.service';
import router from '@/router/index';

const state = {
  processing: false,
  accessToken: StorageService.getToken(),
  refreshTokenPromise: null,
  serverResponse: null,
};

const getters = {
  loggedIn: (state) => {
    return state.accessToken ? true : false;
  },

  processing: (state) => {
    return state.processing;
  },

  serverResponse: (state) => {
    return state.serverResponse;
  },
};

const actions = {
  async login({ commit }, { data }) {
    commit('requesting');

    try {
      const response = await UserService.login(data);
      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        commit('requestSuccess', response.token);

        StorageService.saveToken(response.token);
        StorageService.saveItem('user', JSON.stringify(response.user));
        const logoutTime = new Date();
        logoutTime.setTime(logoutTime.getTime() + 1 * 60 * 60 * 1000);
        StorageService.saveItem('logoutTime', logoutTime);
        ApiService.setHeaders();

        router.push(router.history.current.query.redirect || '/');

        return true;
      } else if (response.code == ApiService.SUCCESS_RESPONSE_STATUS_ACTIVATE) {
        commit('requestSuccess', response.token);

        StorageService.saveToken(response.token);
        StorageService.saveItem('user', JSON.stringify(response.user));
        ApiService.setHeaders();

        router.push('/account/activate-account');

        return true;
      } else {
        commit('requestError', response);

        return false;
      }
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }

      return false;
    }
  },

  logout({ commit }) {
    UserService.logout();
    commit('logoutSuccess');
    router.push('/login');
  },

  refreshToken({ commit, state }) {
    if (!state.refreshTokenPromise) {
      const p = UserService.refreshToken();
      commit('refreshTokenPromise', p);

      p.then(
        (response) => {
          commit('refreshTokenPromise', null);
          commit('requestSuccess', response);
        },
        (error) => {
          commit('refreshTokenPromise', null);
        }
      );
    }

    return state.refreshTokenPromise;
  },

  async register({ commit }, { data }) {
    commit('requesting');

    try {
      const response = await UserService.createAccount(data);

      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        StorageService.saveToken(response.token);
        ApiService.setHeaders();
        commit('requestSuccess', response.token);

        // Redirect the user to activation page
        router.push('/account/activate-account');

        return true;
      } else {
        commit('requestError', response);

        return false;
      }
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }

      return false;
    }
  },

  async activate({ commit }, { data }) {
    commit('requesting');

    try {
      const response = await UserService.activateAccount(data);

      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        StorageService.saveToken(response.token);

        commit('requestSuccess', response.token);

        // Redirect the user to activation page
        router.push('/dashboard');

        return true;
      } else {
        commit('requestError', response);

        return false;
      }
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }

      return false;
    }
  },

  async resetPassword({ commit }, { data }) {
    commit('requesting');

    try {
      const response = await UserService.resetPassword(data);

      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        StorageService.saveToken(response.token);

        commit('requestSuccess', response.token);

        UserService.logout();

        // Redirect the user to login page
        router.push('/account/login');

        return true;
      } else {
        commit('requestError', response);
        return false;
      }
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }

      return false;
    }
  },

  async deactivateAccount({ commit }, { data }) {
    commit('requesting');

    try {
      const response = await UserService.deactivateAccount(data);

      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        commit('requestComplete');

        UserService.logout();

        // Redirect the user to login page
        router.push('/account/login');

        return true;
      } else {
        commit('requestError', response);
        return false;
      }
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }

      return false;
    }
  },
};

const mutations = {
  requesting(state) {
    state.processing = true;
    state.serverResponse = null;
  },

  requestComplete(state) {
    state.processing = false;
  },

  requestSuccess(state, accessToken) {
    state.accessToken = accessToken;
    state.processing = false;
  },

  requestError(state, response) {
    state.processing = false;
    state.serverResponse = response;
  },

  logoutSuccess(state) {
    state.accessToken = '';
  },

  refreshTokenPromise(state, promise) {
    state.refreshTokenPromise = promise;
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
