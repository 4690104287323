import axios from 'axios';
import { StorageService } from './storage.service';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  SUCCESS_RESPONSE_CODE: '0001',

  SUCCESS_RESPONSE_STATUS: 'Success',

  SUCCESS_RESPONSE_STATUS_ACTIVATE: '0003',

  init(baseURL) {
    axios.defaults.baseURL = baseURL;

    // before a request is made start the nprogress
    axios.interceptors.request.use((config) => {
      NProgress.start();
      return config;
    });

    // before a response is returned stop nprogress
    axios.interceptors.response.use((response) => {
      NProgress.done();
      return response;
    });
  },

  setHeaders() {
    //this.setAuthorizationHeader()
    this.setChannelHeader();
  },

  setAuthorizationHeader() {
    //axios.defaults.headers.common["Authorization"] = `${StorageService.getToken()}`
  },

  // headers for admins
  // setChannelHeader() {
  //   axios.defaults.headers = {
  //     'Request-Channel': 'web',
  //     Authorization: `${StorageService.getToken()}`,
  //     'User-Type': 'internal',
  //   };
  // },

  //headers for users
  setChannelHeader() {
    axios.defaults.headers = {
      'Request-Channel': 'web',
      Authorization: `${StorageService.getToken()}`,
    };
  },

  clearHeaders() {
    axios.defaults.headers.common = {};
    axios.defaults.headers = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource, payload) {
    return axios.delete(resource, { data: payload });
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  },
  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.request.status == 401) {
          if (error.config.url.includes('/o/token/')) {
            // Refresh token has failed. Logout the user
            store.dispatch('auth/logout');
            throw error;
          } else {
            // Refresh the access token
            try {
              await store.dispatch('auth/refreshToken');
              // Retry the original request
              return this.customRequest({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data,
              });
            } catch (e) {
              // Refresh has failed - reject the original request
              throw error;
            }
          }
        }

        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },
};

export default ApiService;
