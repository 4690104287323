import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: true,
};

const actions = {
  toggleSidebar({ commit }, { device }) {
    commit(device);
  },
  setAction({ commit }, variable) {
    commit('set', variable);
  },
  toggleAction({ commit }, variable) {
    commit('toggle', variable);
  },
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
};

export const coreuiStore = {
  namespaced: true,
  actions,
  state,
  mutations,
};
