import 'regenerator-runtime/runtime';
import 'core-js/stable';
import Vue from 'vue';
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js';
import App from './App';
import router from './router/index';
import { iconsSet as icons } from './assets/icons/icons.js';

import { store } from './store';
import { StorageService } from './services/storage.service';
import ApiService from './services/api.service';

Vue.use(CoreuiVuePro);
Vue.prototype.$log = console.log.bind(console);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App,
  },
  created() {
    ApiService.init(process.env.VUE_APP_ROOT_API);

    if (StorageService.getToken()) {
      ApiService.setHeaders();
    }
  },
});
