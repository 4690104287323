import Vue from 'vue';
import Vuex from 'vuex';

import { coreuiStore } from './coreuiStore';
import { user } from './user.store';
import { auth } from './auth.store';
import { admin } from './admin.store';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: { admin, auth, coreuiStore, user },
});
