import { StorageService } from '@/services/storage.service'
import { UserService, UserError } from '@/services/user.service'
import ApiService from '@/services/api.service'

const state =  {
    user: null,
    loading: false,
    serverResponse: null,
}

const getters = {

    user: (state) => {
        if (state.user == null) {
           state.user = JSON.parse(StorageService.getItem('user')) 
        }
        return state.user
    },

    loading: (state) => {
        return state.loading
    },

    serverResponse: (state) => {
        return state.serverResponse
    },
}

const actions = { 
    async updateProfile({ commit }, { data }) { 

        commit('startRequest')
        try {
            const response = await UserService.updateProfile(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('updateSuccess', response)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async updateSettings({ commit }, { data }) { 

        commit('startRequest')
        try {
            const response = await UserService.updateSettings(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('updateSuccess', response)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async changePassword({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.changePassword(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('stopRequest', response)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async addProject({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.addProject(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                let projects = await UserService.fetchProjects()
                commit('projectSuccess', projects)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async removeProject({ commit }, { id }) { 
        commit('startRequest')
        try {
            const response = await UserService.removeProject(id)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                let projects = await UserService.fetchProjects()
                commit('projectSuccess', projects)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async regenerateKey({ commit }, { id }) { 
        commit('startRequest')
        try {
            const response = await UserService.regenerateKey(id)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                let projects = await UserService.fetchProjects()
                commit('projectSuccess', projects)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async checkPhoneNumberAvailability({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.checkPhoneNumberAvailability(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('stopRequest', response)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async addPhoneNumber({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.addPhoneNumber(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                let phones = await UserService.fetchPhoneNumbers()
                commit('phoneSuccess', phones)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async setPrimaryPhoneNumber({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.setPrimaryPhoneNumber(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                let phones = await UserService.fetchPhoneNumbers()
                commit('phoneSuccess', phones)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async removePhoneNumber({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.removePhoneNumber(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                let phones = await UserService.fetchPhoneNumbers()
                commit('phoneSuccess', phones)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async getCSRF({ commit }) { 
        try {
            const response = await UserService.getCSRF()
            return response.csrf
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async registerBank({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.registerBank(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('updateSuccess', response)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async updateLocation({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.updateLocation(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('updateSuccess', response)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async fundProject({ commit }, { data, id }) { 
        commit('startRequest')
        try {
            const response = await UserService.fundProject(data, id)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('unitSuccess', data.units)
                let projects = await UserService.fetchProjects()
                commit('projectSuccess', projects)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async transferUnits({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.transferUnits(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('unitSuccess', data.units)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async refreshUnits({ commit }) { 
        commit('startRequest')
        try {
            const response = await UserService.refreshUnits()
            commit('unitBalanceSuccess', response.balance)
            return true
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async verifyPayment({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.verifyPayment(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('verifyPaymentSuccess', response.data.balance)
                return true
            } else {
                commit('requestError', response)
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async fetchNewsletterAccount({ commit }) { 
        commit('startRequest')
        try {
            const accountBalance = await UserService.fetchNewsletterAccount()
            commit('newsletterAccountSuccess', accountBalance)
            return true
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async getAgentBalance({ commit }, {agentID}) { 
        commit('startRequest')
        try {
            const response = await UserService.getAgentBalance(agentID)
            commit('agentBalanceSuccess', response.balance)
            return true
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
    async requestSenderRegistration({ commit }, { data }) { 
        commit('startRequest')
        try {
            const response = await UserService.requestSenderRegistration(data)
            if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
                commit('stopRequest')
                return true
            } else {
                commit('stopRequest')
                return false
            }
        } catch (e) {
            if (e instanceof UserError) {
                commit('requestError', {status: e.errorCode, message: e.message})
            }
            return false
        }
    },
}

const mutations = {

    startRequest(state){
       state.loading = true
       state.serverResponse = null
    },
    stopRequest(state, response){
       state.loading = false
       state.serverResponse = response 
    },
    requestError(state, response){
       state.loading = false
       state.serverResponse = response
    },
    updateSuccess(state, response) {
        state.loading = false
        StorageService.saveItem('user', JSON.stringify(state.user))
        state.serverResponse = response
    },
    projectSuccess(state, response){
       state.loading = false
       state.user.third_parties = response.third_parties
       StorageService.saveItem('user', JSON.stringify(state.user))
    },
    phoneSuccess(state, response){
       state.loading = false
       state.user.phones = response.phones
       StorageService.saveItem('user', JSON.stringify(state.user))
    },
    unitSuccess(state, units) {
        state.user.units_balance = parseFloat(state.user.units_balance) - units
        state.loading = false
        StorageService.saveItem('user', JSON.stringify(state.user))
    },
    unitBalanceSuccess(state, balance) {
        state.user.units_balance = balance
        state.loading = false
        StorageService.saveItem('user', JSON.stringify(state.user))
    },
    verifyPaymentSuccess(state, currentUnitsBal) {
        state.user.units_balance =  currentUnitsBal
        state.loading = false
        StorageService.saveItem('user', JSON.stringify(state.user))
    },
    newsletterAccountSuccess(state, accountBalance) {
        state.user.newsletter_account = accountBalance
        state.loading = false
        StorageService.saveItem('user', JSON.stringify(state.user))
    },
    agentBalanceSuccess(state, balance) {
        state.user.agent.balance = balance
        state.loading = false
        StorageService.saveItem('user', JSON.stringify(state.user))
    },
}

export const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}