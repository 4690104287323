import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { StorageService } from '@/services/storage.service';
Vue.use(Router);

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Users
const User = () => import('@/views/users/User');

// Apps -> Email
const EmailApp = () => import('@/views/apps/email/EmailApp');
const Compose = () => import('@/views/apps/email/Compose');
const Inbox = () => import('@/views/apps/email/Inbox');
const Message = () => import('@/views/apps/email/Message');

// Views
const Dashboard = () => import('@/views/Dashboard');
const CreditUser = () => import('@/views/CreditUser');
const Login = () => import('@/views/Login');
const Outbox = () => import('@/views/Outbox');
const Users = () => import('@/views/Users');

// Reports
const PurchaseHistory = () => import('@/views/reports/PurchaseHistory');
const UnitsUsageHistory = () => import('@/views/reports/UnitsUsageHistory');

// Sender Names
const AllSenderNames = () => import('@/views/senderNames/All');
const ApprovedSenderNames = () => import('@/views/senderNames/Approved');
const DeniedSenderNames = () => import('@/views/senderNames/Denied');
const PendingSenderNames = () => import('@/views/senderNames/Pending');

let router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
            title: 'Dashboard',
          },
        },

        {
          path: 'reports',
          redirect: '/reports/units-usage',
          name: 'reports',

          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'units-usage',
              name: 'Units Usage',
              component: UnitsUsageHistory,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'purchase-history',
              name: 'Purchase History',
              component: PurchaseHistory,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },

        {
          path: 'sender-names',
          redirect: '/sender-names/all',
          name: 'Sender Names',

          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'all',
              name: 'All',
              component: AllSenderNames,
            },
            {
              path: 'approved',
              name: 'Approved',
              component: ApprovedSenderNames,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'denied',
              name: 'Denied',
              component: DeniedSenderNames,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'pending',
              name: 'Pending',
              component: PendingSenderNames,
              meta: {
                requiresAuth: true,
                title: 'Dashboard',
              },
            },
          ],
        },

        {
          path: 'credit-user',
          name: 'Credit User',
          component: CreditUser,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: 'outbox',
          name: 'Outbox',
          component: Outbox,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: 'users',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users,
            },
            {
              path: ':id',
              meta: {
                label: 'User Details',
              },
              name: 'User',
              component: User,
            },
          ],
        },
      ],
    },
    {
      path: '/apps/email',
      redirect: '/apps/email/inbox',
      name: 'Email',
      component: EmailApp,
      children: [
        {
          path: 'compose',
          name: 'Compose',
          component: Compose,
        },
        {
          path: 'inbox',
          name: 'Inbox',
          component: Inbox,
        },
        {
          path: 'message',
          name: 'Message',
          component: Message,
        },
      ],
    },
    {
      path: '/account',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
  ],
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const loggedIn = !!StorageService.getToken();

  if (isPublic && onlyWhenLoggedOut && !loggedIn) {
    return next();
  }

  if (requiresAuth && !loggedIn) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/');
  }

  next();
});

router.afterEach((to) => {
  NProgress.done();
  Vue.nextTick(() => {
    document.title = 'Zigatext Administrative Platform :: ' + to.meta.title;
  });
});

export default router;
