import ApiService from './api.service';
import { StorageService } from './storage.service';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class UserError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
   * Login the user and store the access token to StorageService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  login: async function(data) {
    try {
      const response = await ApiService.post('/users/login', data);
      ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },

  /**
   * Refresh the access token.
   **/
  refreshToken: async function() {
    const refreshToken = StorageService.getRefreshToken();

    const requestData = {
      method: 'post',
      url: '/o/token/',
      data: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
      auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_CLIENT_SECRET,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      StorageService.saveToken(response.data.access_token);
      StorageService.saveRefreshToken(response.data.refresh_token);
      // Update the header in ApiService
      //ApiService.setHeaders();

      return response.data.access_token;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    StorageService.removeToken();
    StorageService.removeUser();
    StorageService.removeRefreshToken();
    ApiService.clearHeaders();
    ApiService.unmount401Interceptor();
  },

  /**
   * create new zigatext account
   *
   * @returns account creation status
   * @throws AuthenticationError
   **/
  createAccount: async function(data) {
    try {
      const response = await ApiService.post('/users/account', data);
      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },

  /**
   * activate zigatext account
   *
   * @returns account activation status
   * @throws AuthenticationError
   **/
  activateAccount: async function(data) {
    try {
      const response = await ApiService.put('/users/activate-account', data);

      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },

  /**
   * reset account password
   *
   * @returns  status
   * @throws AuthenticationError
   **/
  resetPassword: async function(data) {
    try {
      const response = await ApiService.put('/users/reset-password', data);

      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },

  /**
   * update profile
   *
   * @returns  response
   * @throws UserError
   **/
  updateProfile: async function(data) {
    try {
      const response = await ApiService.put('/users/profile', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * update settings
   *
   * @returns  response
   * @throws UserError
   **/
  updateSettings: async function(data) {
    try {
      const response = await ApiService.put('/users/settings', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * De-activate account
   *
   * @returns  response
   * @throws UserError
   **/
  deactivateAccount: async function(data) {
    try {
      const response = await ApiService.delete('/users/account', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * change password
   *
   * @returns  response
   * @throws UserError
   **/
  changePassword: async function(data) {
    try {
      const response = await ApiService.put('/users/change-password', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * add project
   *
   * @returns  response
   * @throws UserError
   **/
  addProject: async function(data) {
    try {
      const response = await ApiService.post('/developers/access-keys', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * list projects
   *
   * @returns  projects
   * @throws UserError
   **/
  fetchProjects: async function() {
    try {
      const response = await ApiService.get('/developers/access-keys');

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * remove
   *
   * @returns  response
   * @throws UserError
   **/
  removeProject: async function(id) {
    try {
      const response = await ApiService.delete(`developers/access-keys/${id}`);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * regenerate project access key
   *
   * @returns  response
   * @throws UserError
   **/
  regenerateKey: async function(id) {
    try {
      const response = await ApiService.put(
        `/developers/access-keys/${id}/regenerate`
      );

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * check phone number availability
   *
   * @returns  response
   * @throws UserError
   **/
  checkPhoneNumberAvailability: async function(data) {
    try {
      const response = await ApiService.post(
        '/users/phone-number-available',
        data
      );

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * add phone number
   *
   * @returns  response
   * @throws UserError
   **/
  addPhoneNumber: async function(data) {
    try {
      const response = await ApiService.post('/users/phone-number', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * set primary phone number
   *
   * @returns  response
   * @throws UserError
   **/
  setPrimaryPhoneNumber: async function(data) {
    try {
      const response = await ApiService.put(
        '/users/primary-phone-number',
        data
      );

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * remove phone number
   *
   * @returns  response
   * @throws UserError
   **/
  removePhoneNumber: async function(data) {
    try {
      const response = await ApiService.delete('/users/phone-number', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * fetch user phone numbers
   *
   * @returns  response
   * @throws UserError
   **/
  fetchPhoneNumbers: async function() {
    try {
      const response = await ApiService.get('/users/phone-number');

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * get crsf token
   *
   * @returns  response
   * @throws UserError
   **/
  getCSRF: async function() {
    try {
      const response = await ApiService.get(
        'https://app.zigatext.com/images/csrf'
      );

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * register bank
   *
   * @returns  response
   * @throws UserError
   **/
  registerBank: async function(data) {
    try {
      const response = await ApiService.post('/users/bank', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * update location
   *
   * @returns  response
   * @throws UserError
   **/
  updateLocation: async function(data) {
    try {
      const response = await ApiService.put('/users/location', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * fund project
   *
   * @returns  response
   * @throws UserError
   **/
  fundProject: async function(data, id) {
    try {
      const response = await ApiService.put(
        `/developers/access-keys/${id}/fund`,
        data
      );

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * transfer units
   *
   * @returns  response
   * @throws UserError
   **/
  transferUnits: async function(data) {
    try {
      const response = await ApiService.put('/credit-units/transfer', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * refresh units
   *
   * @returns  response
   * @throws UserError
   **/
  refreshUnits: async function(data) {
    try {
      const response = await ApiService.get('/credit-units/balance');

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * verify units purchase
   *
   * @returns  response
   * @throws UserError
   **/
  verifyPayment: async function(data) {
    try {
      const response = await ApiService.put(
        '/credit-units/verify-payment',
        data
      );

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * Fetch newsletter account balance.
   *
   * @returns AccoutBalance
   * @throws UserError
   **/
  fetchNewsletterAccount: async function() {
    try {
      const response = await ApiService.get('newsletters/account-balance');

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * Get agent current balance.
   *
   * @returns ResponseStatus
   * @throws UserError
   **/
  getAgentBalance: async function(agentID) {
    try {
      const response = await ApiService.get(`agents/${agentID}/balance`);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },

  /**
   * Get make request for new sender name.
   *
   * @returns ResponseStatus
   * @throws UserError
   **/
  requestSenderRegistration: async function(data) {
    try {
      const response = await ApiService.post('users/sender-registration', data);

      return response.data;
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.detail);
    }
  },
};

export { UserService, AuthenticationError, UserError };
