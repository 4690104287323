import { AdminService, AdminError } from '@/services/admin.service';
import ApiService from '@/services/api.service';

const state = {
  stats: [
    { total: 0, name: '' },
    { total: 0, name: '' },
    { total: 0, name: '' },
    { total: 0, name: '' },
    { total: 0, name: '' },
    { total: 0, name: '' },
    { total: 0, name: '' },
  ],
  outbox: { outbox: [] },
  senderNames: [],
  users: { users: [] },
  paymentModes: { payment_modes: [] },
  currencies: { currencies: [] },
  serverResponse: null,
  loading: false,
  internalStaffRoles: { user_roles: [] },
  internalStaffTypes: {},
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  serverResponse: (state) => {
    return state.serverResponse;
  },
  stats: (state) => {
    return state.stats;
  },
  outbox: (state) => {
    return state.outbox;
  },
  senderNames: (state) => {
    return state.senderNames;
  },
  users: (state) => {
    return state.users;
  },
  paymentModes: (state) => {
    return state.paymentModes;
  },
  currencies: (state) => {
    return state.currencies;
  },
  internalStaffRoles: (state) => {
    return state.internalStaffRoles;
  },
  internalStaffTypes: (state) => {
    return state.internalStaffTypes;
  },
};

const actions = {
  async fetchAdminStats({ commit }) {
    try {
      const response = await AdminService.fetchAdminStats();
      commit('fetchStatsSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }

      return false;
    }
  },
  async fetchOutbox({ commit }, otherQueryParams) {
    try {
      const response = await AdminService.fetchOutbox(otherQueryParams);
      commit('fetchOutboxSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }

      return false;
    }
  },

  async fetchSenderNames({ commit }, id) {
    try {
      const response = await AdminService.fetchSenderNames(id);
      commit('fetchSenderNamesSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }

      return false;
    }
  },

  async fetchUsers({ commit }, otherQueryParams) {
    try {
      const response = await AdminService.fetchUsers(otherQueryParams);
      commit('fetchUsersSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }

      return false;
    }
  },

  async fetchPaymentModes({ commit }) {
    try {
      const response = await AdminService.fetchPaymentModes();
      commit('fetchPaymentModesSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }

      return false;
    }
  },

  async fetchCurrencies({ commit }) {
    try {
      const response = await AdminService.fetchCurrencies();
      commit('fetchCurrenciesSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }

      return false;
    }
  },

  async creditUnits({ commit }, { data }) {
    commit('startRequest');
    try {
      const response = await AdminService.creditUnits(data);
      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        commit('unitSuccess');
        return true;
      } else {
        commit('requestError', response);
        return false;
      }
    } catch (e) {
      if (e instanceof AdminError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }
      return false;
    }
  },

  async fetchInternalStaffsRoles({ commit }) {
    try {
      const response = await AdminService.fetchInternalStaffsRoles();
      commit('fetchInternalStaffsRolesSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }
      return false;
    }
  },

  async fetchInternalStaffsTypes({ commit }) {
    try {
      const response = await AdminService.fetchInternalStaffsTypes();
      commit('fetchInternalStaffsTypesSuccess', response);
      return true;
    } catch (e) {
      if (e instanceof AdminError) {
        commit('dataFetchAllError', {
          status: e.errorCode,
          message: e.message,
        });
      }
      return false;
    }
  },

  async patchInternalStaffs({ commit }, { data }) {
    commit('startRequest');
    try {
      const response = await AdminService.patchInternalStaffs(data);
      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        commit('patchInternalStaffsSuccess');
        return true;
      } else {
        commit('requestError', response);
        return false;
      }
    } catch (e) {
      if (e instanceof AdminError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }
      return false;
    }
  },

  async postInternalStaffs({ commit }, { data }) {
    commit('startRequest');
    try {
      const response = await AdminService.postInternalStaffs(data);
      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        commit('postInternalStaffsSuccess');
        return true;
      } else {
        commit('requestError', response);
        return false;
      }
    } catch (e) {
      if (e instanceof AdminError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }
      return false;
    }
  },

  async patchSenderName({ commit }, { data }) {
    commit('startRequest');
    try {
      const response = await AdminService.patchSenderName(data);
      if (response.code == ApiService.SUCCESS_RESPONSE_CODE) {
        commit('patchSenderNameSuccess');
        return true;
      } else {
        commit('requestError', response);
        return false;
      }
    } catch (e) {
      if (e instanceof AdminError) {
        commit('requestError', { status: e.errorCode, message: e.message });
      }
      return false;
    }
  },
};

const mutations = {
  dataFetchAllError(state, response) {
    state.serverResponse = response;
  },
  fetchInternalStaffsRolesSuccess(state, response) {
    state.internalStaffRoles = response;
  },
  fetchInternalStaffsTypesSuccess(state, response) {
    state.internalStaffTypes = response;
  },
  fetchStatsSuccess(state, response) {
    state.stats = response;
  },
  fetchOutboxSuccess(state, response) {
    state.outbox = response;
  },
  fetchSenderNamesSuccess(state, response) {
    state.senderNames = response;
  },
  fetchUsersSuccess(state, response) {
    state.users = response;
  },
  fetchPaymentModesSuccess(state, response) {
    state.paymentModes = response;
  },
  fetchCurrenciesSuccess(state, response) {
    state.currencies = response;
  },
  startRequest(state) {
    state.loading = true;
    state.serverResponse = null;
  },
  requestError(state, response) {
    state.loading = false;
    state.serverResponse = response;
  },
  unitSuccess(state) {
    state.loading = false;
  },
  patchInternalStaffsSuccess(state) {
    state.loading = false;
  },
  postInternalStaffsSuccess(state) {
    state.loading = false;
  },
  patchSenderNameSuccess(state) {
    state.loading = false;
  },
};

export const admin = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
